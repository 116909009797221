import Translator from '@js/translator'

export const defaultValidationMessages = {
  alpha: () => Translator.trans('u2.alpha', {}, 'validators'),
  exactLength: (limit: number) => Translator.trans('u2.exactLength', { limit }, 'validators'),
  maxLength: (limit: number) => Translator.trans('u2.maxLength', { limit }, 'validators'),
  minLength: (limit: number) => Translator.trans('u2.minLength', { limit }, 'validators'),
  arrayMaxLength: (limit: number) =>
    Translator.trans('u2.array.maxLength', { limit }, 'validators'),
  arrayMinLength: (limit: number) =>
    Translator.trans('u2.array.minLength', { limit }, 'validators'),
  numeric: () => Translator.trans('u2.numeric', {}, 'validators'),
  required: () => Translator.trans('u2.required', {}, 'validators'),
  cronExpression: (value: string) =>
    Translator.trans(
      'u2.frequency.invalid_cron_expression',
      { cron_expression: value },
      'validators'
    ),
  germanDateFormat: () => Translator.trans('u2.german_date_format', {}, 'validators'),
  email: () => Translator.trans('u2.email', {}, 'validators'),
  sameAsPassword: () => Translator.trans('u2.password.do_not_match', {}, 'validators'),
  passwordTooShort: (minPasswordLength: number) =>
    Translator.trans('u2.password.too_short', { minPasswordLength }, 'validators'),
  hasUppercase: () =>
    Translator.trans('u2.password.requires_at_least_one_uppercase_letter', {}, 'validators'),
  hasLowercase: () =>
    Translator.trans('u2.password.requires_at_least_one_lowercase_letter', {}, 'validators'),
  hasNumber: () => Translator.trans('u2.password.requires_at_least_one_number', {}, 'validators'),
  hasNonAlphanumeric: () =>
    Translator.trans(
      'u2.password.requires_at_least_one_non_alphanumeric_character',
      {},
      'validators'
    ),
  circularItemReference: (item_ref_id: string) =>
    Translator.trans('u2_dtm.item_formula.circular_reference', { item_ref_id }, 'validators'),
}
