import invariant from 'tiny-invariant'
import BaseLayout from '@js/layouts/BaseLayout.vue'
import Translator from '@js/translator'
import urlToShortName from '@js/assets/router/urlToShortName'
import { useTaskInfoStore } from '@js/stores/task-info'
import { useTaskListInfoStore } from '@js/stores/task-list-info'
import { useTaskStore } from '@js/stores/task'
import type { AuthorizationString } from '@js/model/authorization'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

const newRoutes = [
  {
    title: () => `${Translator.trans('u2.new')} APM Transactions`,
    name: 'ApmTransactionNew',
    path: '/apm/transaction/new',
  },
  {
    title: () =>
      `${Translator.trans('u2.new')} ${Translator.trans('u2_contractmanagement.contract')}`,
    name: 'ContractNew',
    path: '/contract-management/contract/new',
  },
  {
    title: () =>
      `${Translator.trans('u2.new')} ${Translator.trans('u2_tpm.country_by_country_report')}`,
    name: 'CountryByCountryReportNew',
    path: '/tpm/country-by-country-report/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} ${Translator.trans('u2_tpm.financial_data')}`,
    name: 'FinancialDataNew',
    path: '/tpm/financial-data/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} IGT 1 - Inter-company Loans / Finance`,
    name: 'Igt1TransactionNew',
    path: '/igt/transaction/igt1/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} IGT 2 - Derivatives`,
    name: 'Igt2TransactionNew',
    path: '/igt/transaction/igt2/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} IGT 3 - Reinsurance`,
    name: 'Igt3TransactionNew',
    path: '/igt/transaction/igt3/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} IGT 4 - Inter-company`,
    name: 'Igt4TransactionNew',
    path: '/igt/transaction/igt4/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} IGT 5 - Inter-company`,
    name: 'Igt5TransactionNew',
    path: '/igt/transaction/igt5/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} ${Translator.trans('u2_tam.income_tax_planning')}`,
    name: 'IncomeTaxPlanningNew',
    path: '/tam/income-tax-planning/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} ${Translator.trans('u2_tpm.local_file')}`,
    name: 'LocalFileNew',
    path: '/tpm/local-file/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} ${Translator.trans('u2_tam.loss_carry_forward')}`,
    name: 'LossCarryForwardNew',
    path: '/tam/loss-carry-forward/new',
  },
  {
    title: () =>
      `${Translator.trans('u2.new')} ${Translator.trans('u2_tpm.main_business_activity')}`,
    name: 'MainBusinessActivityNew',
    path: '/tpm/main-business-activity/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} ${Translator.trans('u2_tpm.master_file')}`,
    name: 'MasterFileNew',
    path: '/tpm/master-file/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} ${Translator.trans('u2_tcm.other_deadline')}`,
    name: 'OtherDeadlineNew',
    path: '/tcm/other-deadline/new',
  },
  {
    title: () =>
      `${Translator.trans('u2.new')} ${Translator.trans('u2_tcm.tax_assessment_monitor')}`,
    name: 'TaxAssessmentMonitorNew',
    path: '/tcm/tax-assessment-monitor/new',
  },
  {
    title: () =>
      `${Translator.trans('u2.new')} ${Translator.trans('u2_tam.tax_assessment_status')}`,
    name: 'TaxAssessmentStatusNew',
    path: '/tam/tax-assessment-status/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} ${Translator.trans('u2_tam.tax_audit_risk')}`,
    name: 'TaxAuditRiskNew',
    path: '/tam/tax-audit-risk/new',
  },
  {
    title: () =>
      `${Translator.trans('u2.new')} ${Translator.trans('u2_tcm.tax_authority_audit_objection')}`,
    name: 'TaxAuthorityAuditObjectionNew',
    path: '/tcm/tax-authority-audit-objection/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} ${Translator.trans('u2_tam.tax_consulting_fee')}`,
    name: 'TaxConsultingFeeNew',
    path: '/tam/tax-consulting-fee/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} ${Translator.trans('u2_tam.tax_credit')}`,
    name: 'TaxCreditNew',
    path: '/tam/tax-credit/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} ${Translator.trans('u2_tcm.tax_filing_monitor')}`,
    name: 'TaxFilingMonitorNew',
    path: '/tcm/tax-filing-monitor/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} ${Translator.trans('u2_tam.tax_litigation')}`,
    name: 'TaxLitigationNew',
    path: '/tam/tax-litigation/new',
  },
  {
    title: () =>
      `${Translator.trans('u2.new')} ${Translator.trans('u2_tam.tax_relevant_restriction')}`,
    name: 'TaxRelevantRestrictionNew',
    path: '/tam/tax-relevant-restriction/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} ${Translator.trans('u2_tam.tax_rate')}`,
    name: 'TaxRateNew',
    path: '/tam/tax-rate/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} ${Translator.trans('u2_tpm.transaction')}`,
    name: 'TransactionNew',
    path: '/tpm/transaction/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} ${Translator.trans('u2_tam.transfer_pricing')}`,
    name: 'TransferPricingNew',
    path: '/tam/transfer-pricing/new',
  },
  {
    title: () => `${Translator.trans('u2.new')} ${Translator.trans('u2_dtm.status_monitor')}`,
    name: 'UnitPeriodNew',
    path: '/tax-accounting/unit-period/new',
  },
]
const editRoutes = [
  {
    name: 'ApmTransactionEdit',
    path: '/apm/transaction/:id/edit',
  },
  {
    name: 'ContractEdit',
    path: '/contract-management/contract/:id/edit',
  },
  {
    name: 'CountryByCountryReportEdit',
    path: '/tpm/country-by-country-report/:id/edit',
  },
  {
    name: 'FinancialDataEdit',
    path: '/tpm/financial-data/:id/edit',
  },
  {
    name: 'Igt1TransactionEdit',
    path: '/igt/transaction/igt1/:id/edit',
  },
  {
    name: 'Igt2TransactionEdit',
    path: '/igt/transaction/igt2/:id/edit',
  },
  {
    name: 'Igt3TransactionEdit',
    path: '/igt/transaction/igt3/:id/edit',
  },
  {
    name: 'Igt4TransactionEdit',
    path: '/igt/transaction/igt4/:id/edit',
  },
  {
    name: 'Igt5TransactionEdit',
    path: '/igt/transaction/igt5/:id/edit',
  },
  {
    name: 'IncomeTaxPlanningEdit',
    path: '/tam/income-tax-planning/:id/edit',
  },
  {
    name: 'LocalFileEdit',
    path: '/tpm/local-file/:id/edit',
  },
  {
    name: 'LossCarryForwardEdit',
    path: '/tam/loss-carry-forward/:id/edit',
  },
  {
    name: 'MainBusinessActivityEdit',
    path: '/tpm/main-business-activity/:id/edit',
  },
  {
    name: 'MasterFileEdit',
    path: '/tpm/master-file/:id/edit',
  },
  {
    name: 'OtherDeadlineEdit',
    path: '/tcm/other-deadline/:id/edit',
  },
  {
    name: 'TaxAssessmentMonitorEdit',
    path: '/tcm/tax-assessment-monitor/:id/edit',
  },
  {
    name: 'TaxAssessmentStatusEdit',
    path: '/tam/tax-assessment-status/:id/edit',
  },
  {
    name: 'TaxAuditRiskEdit',
    path: '/tam/tax-audit-risk/:id/edit',
  },
  {
    name: 'TaxAuthorityAuditObjectionEdit',
    path: '/tcm/tax-authority-audit-objection/:id/edit',
  },
  {
    name: 'TaxConsultingFeeEdit',
    path: '/tam/tax-consulting-fee/:id/edit',
  },
  {
    name: 'TaxCreditEdit',
    path: '/tam/tax-credit/:id/edit',
  },
  {
    name: 'TaxFilingMonitorEdit',
    path: '/tcm/tax-filing-monitor/:id/edit',
  },
  {
    name: 'TaxLitigationEdit',
    path: '/tam/tax-litigation/:id/edit',
  },
  {
    name: 'TaxRelevantRestrictionEdit',
    path: '/tam/tax-relevant-restriction/:id/edit',
  },
  {
    name: 'TaxRateEdit',
    path: '/tam/tax-rate/:id/edit',
  },
  {
    name: 'TransactionEdit',
    path: '/tpm/transaction/:id/edit',
  },
  {
    name: 'TransferPricingEdit',
    path: '/tam/transfer-pricing/:id/edit',
  },
  {
    name: 'UnitPeriodEdit',
    path: '/tax-accounting/unit-period/:id/edit',
  },
]
const listRoutes: Array<{
  name: string
  title: () => string
  path: string
  auth?: AuthorizationString
}> = [
  {
    name: 'ApmTransactionList',
    path: '/apm/transaction',
    title: () => 'APM Transactions',
    auth: 'APM_TRANSACTION:READ',
  },
  {
    name: 'ContractList',
    path: '/contract-management/contract',
    title: () => Translator.trans('u2_contractmanagement.contract'),
    auth: 'CM_CONTRACT:READ',
  },
  {
    name: 'CountryByCountryReportList',
    path: '/tpm/country-by-country-report',
    title: () => Translator.trans('u2_tpm.country_by_country_report'),
    auth: 'TPM_COUNTRY_BY_COUNTRY_REPORT:ACCESS',
  },
  {
    name: 'FinancialDataList',
    path: '/tpm/financial-data',
    title: () => Translator.trans('u2_tpm.financial_data'),
    auth: 'TPM_FINANCIAL_DATA:READ',
  },
  {
    name: 'Igt1TransactionList',
    path: '/igt/transaction/igt1',
    title: () => 'IGT 1 - Inter-company Loans / Finance',
    auth: 'IGT_IGT1_TRANSACTION:READ',
  },
  {
    name: 'Igt2TransactionList',
    path: '/igt/transaction/igt2',
    title: () => 'IGT 2 - Derivatives',
    auth: 'IGT_IGT2_TRANSACTION:READ',
  },
  {
    name: 'Igt3TransactionList',
    path: '/igt/transaction/igt3',
    title: () => 'IGT 3 - Reinsurance',
    auth: 'IGT_IGT3_TRANSACTION:READ',
  },
  {
    name: 'Igt4TransactionList',
    path: '/igt/transaction/igt4',
    title: () => 'IGT 4 - Inter-company',
    auth: 'IGT_IGT4_TRANSACTION:READ',
  },
  {
    name: 'Igt5TransactionList',
    path: '/igt/transaction/igt5',
    title: () => 'IGT 5',
    auth: 'IGT_IGT5_TRANSACTION:READ',
  },
  {
    name: 'IncomeTaxPlanningList',
    path: '/tam/income-tax-planning',
    title: () => Translator.trans('u2_tam.income_tax_planning'),
    auth: 'TAM_INCOME_TAX_PLANNING:READ',
  },
  {
    name: 'LocalFileList',
    path: '/tpm/local-file',
    title: () => Translator.trans('u2_tpm.local_file.plural'),
    auth: 'TPM_LOCAL_FILE:ACCESS',
  },
  {
    name: 'LossCarryForwardList',
    path: '/tam/loss-carry-forward',
    title: () => Translator.trans('u2_tam.loss_carry_forward'),
    auth: 'TAM_LOSS_CARRY_FORWARD:READ',
  },
  {
    name: 'MainBusinessActivityList',
    path: '/tpm/main-business-activity',
    title: () => Translator.trans('u2_tpm.main_business_activity'),
    auth: 'TPM_MAIN_BUSINESS_ACTIVITY:READ',
  },
  {
    name: 'MasterFileList',
    path: '/tpm/master-file',
    title: () => Translator.trans('u2_tpm.master_file'),
    auth: 'TPM_MASTER_FILE:ACCESS',
  },
  {
    name: 'OtherDeadlineList',
    path: '/tcm/other-deadline',
    title: () => Translator.trans('u2_tcm.other_deadline'),
    auth: 'TCM_OTHER_DEADLINE:READ',
  },
  {
    name: 'TaxAssessmentMonitorList',
    path: '/tcm/tax-assessment-monitor',
    title: () => Translator.trans('u2_tcm.tax_assessment_monitor'),
    auth: 'TCM_TAX_ASSESSMENT_MONITOR:READ',
  },
  {
    name: 'TaxAssessmentStatusList',
    path: '/tam/tax-assessment-status',
    title: () => Translator.trans('u2_tam.tax_assessment_status'),
    auth: 'TAM_TAX_ASSESSMENT_STATUS:READ',
  },
  {
    name: 'TaxAuditRiskList',
    path: '/tam/tax-audit-risk',
    title: () => Translator.trans('u2_tam.tax_audit_risk'),
    auth: 'TAM_TAX_AUDIT_RISK:READ',
  },
  {
    name: 'TaxAuthorityAuditObjectionList',
    path: '/tcm/tax-authority-audit-objection',
    title: () => Translator.trans('u2_tcm.tax_authority_audit_objection'),
    auth: 'TCM_TAX_AUTHORITY_AUDIT_OBJECTION:READ',
  },
  {
    name: 'TaxConsultingFeeList',
    path: '/tam/tax-consulting-fee',
    title: () => Translator.trans('u2_tam.tax_consulting_fee'),
    auth: 'TAM_TAX_CONSULTING_FEE:READ',
  },
  {
    name: 'TaxCreditList',
    path: '/tam/tax-credit',
    title: () => Translator.trans('u2_tam.tax_credit'),
    auth: 'TAM_TAX_CREDIT:READ',
  },
  {
    name: 'TaxFilingMonitorList',
    path: '/tcm/tax-filing-monitor',
    title: () => Translator.trans('u2_tcm.tax_filing_monitor'),
    auth: 'TCM_TAX_FILING_MONITOR:READ',
  },
  {
    name: 'TaxLitigationList',
    path: '/tam/tax-litigation',
    title: () => Translator.trans('u2_tam.tax_litigation'),
    auth: 'TAM_TAX_LITIGATION:READ',
  },
  {
    name: 'TaxRelevantRestrictionList',
    path: '/tam/tax-relevant-restriction',
    title: () => Translator.trans('u2_tam.tax_relevant_restriction'),
    auth: 'TAM_TAX_RELEVANT_RESTRICTION:READ',
  },
  {
    name: 'TaxRateList',
    path: '/tam/tax-rate',
    title: () => Translator.trans('u2_tam.tax_rate'),
    auth: 'TAM_TAX_RATE:READ',
  },
  {
    name: 'TransactionList',
    path: '/tpm/transaction',
    title: () => Translator.trans('u2_tpm.transaction'),
    auth: 'TPM_TRANSACTION:READ',
  },
  {
    name: 'TransferPricingList',
    path: '/tam/transfer-pricing',
    title: () => Translator.trans('u2_tam.transfer_pricing'),
    auth: 'TAM_TRANSFER_PRICING:READ',
  },
  {
    name: 'UnitPeriodList',
    path: '/tax-accounting/unit-period',
    title: () => Translator.trans('u2_dtm.status_monitor'),
    auth: 'UNIT_PERIOD:READ',
  },
]
const documentRoutes = [
  {
    name: 'ApmTransactionDocument',
    path: '/apm/transaction/:id/edit-document',
  },
  {
    name: 'ContractDocument',
    path: '/contract-management/contract/:id/edit-document',
  },
  {
    name: 'CountryByCountryReportDocument',
    path: '/tpm/country-by-country-report/:id/edit-document',
  },
  {
    name: 'FinancialDataDocument',
    path: '/tpm/financial-data/:id/edit-document',
  },
  {
    name: 'Igt1TransactionDocument',
    path: '/igt/transaction/igt1/:id/edit-document',
  },
  {
    name: 'Igt2TransactionDocument',
    path: '/igt/transaction/igt2/:id/edit-document',
  },
  {
    name: 'Igt3TransactionDocument',
    path: '/igt/transaction/igt3/:id/edit-document',
  },
  {
    name: 'Igt4TransactionDocument',
    path: '/igt/transaction/igt4/:id/edit-document',
  },
  {
    name: 'Igt5TransactionDocument',
    path: '/igt/transaction/igt5/:id/edit-document',
  },
  {
    name: 'IncomeTaxPlanningDocument',
    path: '/tam/income-tax-planning/:id/edit-document',
  },
  {
    name: 'LocalFileDocument',
    path: '/tpm/local-file/:id/edit-document',
  },
  {
    name: 'LossCarryForwardDocument',
    path: '/tam/loss-carry-forward/:id/edit-document',
  },
  {
    name: 'MainBusinessActivityDocument',
    path: '/tpm/main-business-activity/:id/edit-document',
  },
  {
    name: 'MasterFileDocument',
    path: '/tpm/master-file/:id/edit-document',
  },
  {
    name: 'OtherDeadlineDocument',
    path: '/tcm/other-deadline/:id/edit-document',
  },
  {
    name: 'TaxAssessmentMonitorDocument',
    path: '/tcm/tax-assessment-monitor/:id/edit-document',
  },
  {
    name: 'TaxAssessmentStatusDocument',
    path: '/tam/tax-assessment-status/:id/edit-document',
  },
  {
    name: 'TaxAuditRiskDocument',
    path: '/tam/tax-audit-risk/:id/edit-document',
  },
  {
    name: 'TaxAuthorityAuditObjectionDocument',
    path: '/tcm/tax-authority-audit-objection/:id/edit-document',
  },
  {
    name: 'TaxConsultingFeeDocument',
    path: '/tam/tax-consulting-fee/:id/edit-document',
  },
  {
    name: 'TaxCreditDocument',
    path: '/tam/tax-credit/:id/edit-document',
  },
  {
    name: 'TaxFilingMonitorDocument',
    path: '/tcm/tax-filing-monitor/:id/edit-document',
  },
  {
    name: 'TaxLitigationDocument',
    path: '/tam/tax-litigation/:id/edit-document',
  },
  {
    name: 'TaxRelevantRestrictionDocument',
    path: '/tam/tax-relevant-restriction/:id/edit-document',
  },
  {
    name: 'TaxRateDocument',
    path: '/tam/tax-rate/:id/edit-document',
  },
  {
    name: 'TransactionDocument',
    path: '/tpm/transaction/:id/edit-document',
  },
  {
    name: 'TransferPricingDocument',
    path: '/tam/transfer-pricing/:id/edit-document',
  },
  {
    name: 'UnitPeriodDocument',
    path: '/tax-accounting/unit-period/:id/edit-document',
  },
]

export default [
  ...newRoutes.map(({ name, path, title }): RouteRecordRaw => {
    return {
      component: () => import('@js/pages/task/TaskNew.vue'),
      meta: {
        layout: BaseLayout,
        title,
      },
      props: (route) => ({ transactionType: route.query.type }),
      name,
      path,
      beforeEnter: async (to) => {
        await useTaskInfoStore().fetchTaskMetadata(urlToShortName(to))
      },
    }
  }),
  ...editRoutes.map(({ name, path }): RouteRecordRaw => {
    return {
      component: () => import('@js/pages/task/TaskEdit.vue'),
      meta: {
        layout: BaseLayout,
      },
      name,
      path,
      props: () => ({ task: useTaskStore().task }),
      beforeEnter: async (to) => {
        const taskInfoStore = useTaskInfoStore()
        await taskInfoStore.fetchTaskInformation(urlToShortName(to), +to.params.id)
        invariant(taskInfoStore.taskId, 'Task ID is not defined')
        await useTaskStore().fetchTaskById(taskInfoStore.taskId)
      },
    }
  }),
  ...listRoutes.map(({ auth, name, path, title }): RouteRecordRaw => {
    return {
      component: () => import('@js/pages/task/TaskList.vue'),
      meta: {
        layout: BaseLayout,
        layoutFormat: 'wide',
        auth,
        globalSearch: {
          icon: 'list',
          name: title,
        },
      },
      name,
      path,
      beforeEnter: async (to) => {
        await useTaskListInfoStore().fetchTaskListInfo(urlToShortName(to), to.query)
      },
    }
  }),
  ...documentRoutes.map(({ name, path }): RouteRecordRaw => {
    return {
      component: () => import('@js/pages/document/DocumentEdit.vue'),
      meta: {
        layout: BaseLayout,
      },
      name,
      path,
      props: () => ({ task: useTaskStore().task }),
      beforeEnter: async (to) => {
        const taskInfoStore = useTaskInfoStore()
        await taskInfoStore.fetchTaskInformation(urlToShortName(to), +to.params.id)
        invariant(taskInfoStore.taskId, 'Task ID is not defined')
        await useTaskStore().fetchTaskById(taskInfoStore.taskId)
      },
    }
  }),

  {
    component: () => import('@js/pages/task/TaskBulkEdit.vue'),
    meta: {
      layout: BaseLayout,
    },
    name: 'TaskBulkEdit',
    path: '/bulk/:shortName/edit',
    props: (route: RouteLocation) => ({ shortName: route.params.shortName }),
  },
] as Array<RouteRecordRaw>
