import axios from 'axios'
import ConfigurationDataLayout from '@js/layouts/ConfigurationDataLayout.vue'
import Translator from '@js/translator'
import type { DocumentTemplate } from '@js/model/document-template'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/document/TemplateList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2_structureddocument.document_templates'),
      },
    },
    name: 'TemplateList',
    path: '/structured-document/document-template',
  },
  {
    component: () => import('@js/pages/document/TemplateImport.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'TemplateImport',
    path: '/structured-document/document-template/import',
  },
  {
    component: () => import('@js/pages/document/TemplatePreview.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      title: (params: { documentTemplate: DocumentTemplate }) =>
        Translator.trans('u2_structureddocument.preview_given_document_template', {
          document_template_name: params.documentTemplate.name,
        }),
    },
    name: 'TemplatePreview',
    path: '/structured-document/document-template/:id/preview',
    props: (to: RouteLocation) => ({
      documentTemplate: to.params.documentTemplate,
    }),
    beforeEnter: async (to: RouteLocation) => {
      to.params.documentTemplate = (await axios.get(`/api/document-templates/${to.params.id}`)).data
    },
  },
  {
    component: () => import('@js/pages/document/TemplateConfigure.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      title: (params: { documentTemplate: DocumentTemplate }) =>
        Translator.trans('u2_structureddocument.document_template') +
        ': ' +
        params.documentTemplate.name,
    },
    name: 'TemplateConfigure',
    path: '/structured-document/document-template/:id/configure',
    props: (to: RouteLocation) => ({
      documentTemplate: to.params.documentTemplate,
    }),
    beforeEnter: async (to: RouteLocation) => {
      to.params.documentTemplate = (await axios.get(`/api/document-templates/${to.params.id}`)).data
    },
  },
] as Array<RouteRecordRaw>
