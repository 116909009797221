import { useQueryClient } from '@tanstack/vue-query'
import { layoutCollectionApi } from '@js/api/layoutCollectionApi'
import { queries } from '@js/query'
import Translator from '@js/translator'
import { useLayoutParametersStore } from '@js/stores/layout-parameters'
import BaseLayout from '@js/layouts/BaseLayout.vue'
import ConfigurationDataLayout from '@js/layouts/ConfigurationDataLayout.vue'
import type { LayoutCollection } from '@js/model/layoutCollection'
import type { Field, Layout } from '@js/model/layout'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'
import type { Unit } from '@js/model/unit'
import type { Period } from '@js/model/period'

export default [
  {
    component: () => import('@js/pages/layout/LayoutCollectionEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'LayoutCollectionEdit',
    path: '/configuration/layout-collection/:id/edit',
    props: (route: RouteLocation) => ({
      id: route.params.id,
    }),
  },
  {
    component: () => import('@js/pages/layout/LayoutCollectionNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'LayoutCollectionNew',
    path: '/configuration/layout-collection/new',
  },
  {
    component: () => import('@js/pages/layout/LayoutCollectionList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2.layout_collection.plural'),
      },
    },
    name: 'LayoutCollectionList',
    path: '/configuration/layout-collection',
  },
  {
    component: () => import('@js/pages/layout/LayoutCollectionUnitView.vue'),
    meta: {
      layout: BaseLayout,
      layoutFormat: 'wide',
    },
    name: 'LayoutCollectionUnitView',
    path: '/layout-collections/:id/layouts/:layoutId/unit-view',
    props: (to: RouteLocation) => ({
      layoutCollection: to.meta.props?.layoutCollection,
      layout: to.meta.props?.selectedLayout,
    }),
    beforeEnter: async (to) => {
      const layoutParamStore = useLayoutParametersStore()
      layoutParamStore.parameters.layoutCollection = String(to.params.id)
      layoutParamStore.parameters.layout = Number(to.params.layoutId) || undefined
      layoutParamStore.parameters.period = to.query ? Number(to.query.period) : undefined
      layoutParamStore.parameters.unit = Number(to.query.unit) || undefined
      layoutParamStore.parameters.field = Number(to.query.field) || undefined
      layoutParamStore.parameters.selectedView = 'unit'

      const queryClient = useQueryClient()
      const assignedLayouts = await queryClient
        .ensureQueryData(
          queries.layoutCollections.single(layoutParamStore.parameters.layoutCollection)._ctx
            .layouts
        )
        .then(({ 'hydra:member': layouts }) => layouts)

      const layout = assignedLayouts.find((layout) => layout.id === +to.params.layoutId)
      if (layout === undefined) {
        return {
          name: 'LayoutCollectionNoLayoutSelected',
          params: {
            id: layoutParamStore.parameters.layoutCollection,
          },
        }
      }

      const { data: layoutCollection } = await layoutCollectionApi.fetchById(
        layoutParamStore.parameters.layoutCollection
      )
      to.meta.props = { layoutCollection, selectedLayout: layout }
    },
  },
  {
    component: () => import('@js/pages/layout/LayoutCollectionGroupView.vue'),
    meta: {
      layout: BaseLayout,
      layoutFormat: 'wide',
    },
    name: 'LayoutCollectionGroupView',
    path: '/layout-collections/:id/layouts/:layoutId/group-view',
    props: (to: RouteLocation) => ({
      layoutCollection: to.meta.props?.layoutCollection,
      layout: to.meta.props?.selectedLayout,
    }),
    beforeEnter: async (to: RouteLocation) => {
      const layoutParamStore = useLayoutParametersStore()
      layoutParamStore.parameters.layoutCollection = String(to.params.id)
      layoutParamStore.parameters.layout = Number(to.params.layoutId) || undefined
      layoutParamStore.parameters.period = to.query ? Number(to.query.period) : undefined
      layoutParamStore.parameters.unitHierarchy = Number(to.query.unitHierarchy) || undefined
      layoutParamStore.parameters.field = Number(to.query.field) || undefined
      layoutParamStore.parameters.selectedView = 'group'

      const queryClient = useQueryClient()

      const assignedLayouts = await queryClient
        .ensureQueryData(
          queries.layoutCollections.single(layoutParamStore.parameters.layoutCollection)._ctx
            .layouts
        )
        .then(({ 'hydra:member': layouts }) => layouts)

      const layout = assignedLayouts.find((layout) => layout.id === +to.params.layoutId)
      if (layout === undefined) {
        return {
          name: 'LayoutCollectionNoLayoutSelected',
          params: {
            id: layoutParamStore.parameters.layoutCollection,
          },
        }
      }

      const { data: layoutCollection } = await layoutCollectionApi.fetchById(
        layoutParamStore.parameters.layoutCollection
      )

      to.meta.props = { layoutCollection, selectedLayout: layout }
    },
  },

  {
    component: () => import('@js/pages/layout/LayoutCollectionGroupViewBreakdown.vue'),
    meta: {
      layout: BaseLayout,
      layoutFormat: 'wide',
    },
    name: 'LayoutCollectionGroupViewBreakdown',
    path: '/layout-collections/:id/layouts/:layoutId/group-view/item-breakdown/:itemId',
  },
  {
    component: () => import('@js/pages/layout/ItemCountryReport.vue'),
    meta: {
      layout: BaseLayout,
      layoutFormat: 'wide',
    },
    name: 'ItemCountryReport',
    path: '/layout-collections/:id/item-country-report/:reportId',
  },
  {
    name: 'LayoutCollectionsHome',
    path: '/layout-collections/:id/layouts/:layoutId',
    redirect: (to) => {
      return {
        name:
          useLayoutParametersStore().parameters.selectedView === 'unit'
            ? 'LayoutCollectionUnitView'
            : 'LayoutCollectionGroupView',
        params: {
          id: to.params.id,
          layoutId: to.params.layoutId,
        },
      }
    },
  },
  {
    component: () => import('@js/pages/layout/LayoutCollectionNoLayoutSelected.vue'),
    name: 'LayoutCollectionNoLayoutSelected',
    path: '/layout-collections/:id/layouts',
    meta: {
      layout: BaseLayout,
    },
    props: (to: RouteLocation) => ({
      layoutCollection: to.meta.props?.layoutCollection,
    }),
    beforeEnter: async (to: RouteLocation) => {
      const layoutParamStore = useLayoutParametersStore()
      layoutParamStore.parameters.layoutCollection = String(to.params.id)
      layoutParamStore.parameters.layout = undefined

      if (to.query.useCurrentParams) {
        return {
          name: 'LayoutCollectionNoLayoutSelected',
          params: {
            id: layoutParamStore.parameters.layoutCollection,
          },
          query: {
            period: layoutParamStore.parameters.period,
            unit:
              layoutParamStore.parameters.selectedView === 'unit'
                ? layoutParamStore.parameters.unit
                : undefined,
            unitHierarchy:
              layoutParamStore.parameters.selectedView === 'group'
                ? layoutParamStore.parameters.unitHierarchy
                : undefined,
            selectedView: layoutParamStore.parameters.selectedView,
          },
        }
      }

      layoutParamStore.parameters.selectedView =
        to.query.selectedView === 'group' ? 'group' : 'unit'
      layoutParamStore.parameters.period = Number(to.query.period) || undefined
      if (layoutParamStore.parameters.selectedView === 'unit') {
        layoutParamStore.parameters.unit = Number(to.query.unit) || undefined
      }
      if (layoutParamStore.parameters.selectedView === 'group') {
        layoutParamStore.parameters.unitHierarchy = Number(to.query.unitHierarchy) || undefined
      }

      const { data: layoutCollection } = await layoutCollectionApi.fetchById(
        layoutParamStore.parameters.layoutCollection
      )

      to.meta.props = { layoutCollection }
    },
  },
] as Array<RouteRecordRaw>

export const buildLayoutCollectionRoute = (
  layoutCollectionId: LayoutCollection['id'],
  selectedView?: 'unit' | 'group',
  layoutId?: Layout['id'],
  fieldId?: Field['id'],
  unitId?: Unit['id'],
  periodId?: Period['id']
) => {
  const layoutParamStore = useLayoutParametersStore()
  if (layoutId === undefined) {
    return {
      name: 'LayoutCollectionNoLayoutSelected',
      params: {
        id: layoutCollectionId,
      },
      query: {
        period: periodId ?? layoutParamStore.parameters.period,
        unit: selectedView === 'unit' ? (unitId ?? layoutParamStore.parameters.unit) : undefined,
        unitHierarchy:
          selectedView === 'group' ? layoutParamStore.parameters.unitHierarchy : undefined,
        selectedView: selectedView,
      },
    }
  }

  if (selectedView === 'unit') {
    return {
      name: 'LayoutCollectionUnitView',
      params: {
        id: layoutCollectionId,
        layoutId,
      },
      query: {
        period: periodId ?? layoutParamStore.parameters.period,
        unit: unitId ?? layoutParamStore.parameters.unit,
        field: fieldId ?? layoutParamStore.parameters.field,
      },
    }
  }

  return {
    name: 'LayoutCollectionGroupView',
    params: {
      id: layoutCollectionId,
      layoutId,
    },
    query: {
      period: periodId ?? layoutParamStore.parameters.period,
      unitHierarchy: layoutParamStore.parameters.unitHierarchy,
      field: fieldId ?? layoutParamStore.parameters.field,
    },
  }
}
