import axios from 'axios'
import { PermissionMasks } from '@js/model/permission'
import type { ItemCountryReport } from '@js/api/itemCountryReportApi'
import type { Layout } from '@js/model/layout'
import type { LayoutCollection } from '@js/model/layoutCollection'
import type { HydraCollectionResponse } from '@js/types'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { AxiosResponse } from 'axios'

export const basePath = '/api/layout-collections'
type LayoutCollectionForm = { name: string; public: boolean }

export const layoutCollectionApi = {
  fetchByQuery: function (query?: {
    page?: number
    itemsPerPage?: number
    pagination?: boolean
    'sort[name]'?: SortingDirection
    'sort[createdAt]'?: SortingDirection
    'sort[updatedAt]'?: SortingDirection
    'sort[createdBy.username]'?: SortingDirection
    'sort[updatedBy.username]'?: SortingDirection
    search?: string | Array<string>
    permission?: 1 | 4 | 8 | 128
  }) {
    return axios.get<HydraCollectionResponse<LayoutCollection>>(basePath, {
      params: { ...query },
    })
  },

  fetchById: function (id: string) {
    return axios.get<LayoutCollection>(`${basePath}/${id}`)
  },

  delete: function (payload: Pick<LayoutCollection, '@id'>) {
    if (!payload['@id']) throw new Error('Unable to delete record without @id')

    return axios.delete<LayoutCollection>(payload['@id'])
  },
  create: function (payload: LayoutCollectionForm) {
    return axios.post<LayoutCollectionForm, AxiosResponse<LayoutCollection>>(`${basePath}`, payload)
  },
  update: function (layoutCollection: LayoutCollection, payload: LayoutCollectionForm) {
    if (!layoutCollection['@id']) throw new Error('Unable to update record without @id in payload')

    return axios.patch<LayoutCollection>(layoutCollection['@id'], payload)
  },
  updateAssignedLayouts: function (
    layoutCollectionId: LayoutCollection['id'],
    payload: Array<Layout['@id']>
  ) {
    return axios.put(`${basePath}/${layoutCollectionId}/layouts`, { layouts: payload })
  },
  fetchAssignedLayouts: function (layoutCollectionId: LayoutCollection['id']) {
    return axios.get<HydraCollectionResponse<Layout>>(
      `${basePath}/${layoutCollectionId}/layouts?permission=` + PermissionMasks.VIEW
    )
  },
  fetchAssignedLayoutsForAdministrators: function (layoutCollectionId: LayoutCollection['id']) {
    return axios.get<HydraCollectionResponse<Layout>>(`${basePath}/${layoutCollectionId}/layouts`)
  },
  fetchAssignedItemCountryReports: function (layoutCollectionId: LayoutCollection['id']) {
    return axios.get<HydraCollectionResponse<ItemCountryReport>>(
      `${basePath}/${layoutCollectionId}/item-country-reports`
    )
  },
}
