import axios from 'axios'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { ApiResourceId, HydraCollectionResponse } from '@js/types'
import type { Currency } from '@js/model/currency'

const basePath = '/api/currencies'

function fetchByQuery(query: {
  page?: number
  iso4217code?: string
  name?: string
  'sort[iso4217code]'?: SortingDirection
  'sort[name]'?: SortingDirection
  pagination?: boolean
  itemsPerPage?: number
}) {
  return axios.get<HydraCollectionResponse<Currency>>(basePath, {
    params: { ...query },
  })
}

function fetchAll() {
  return axios.get<HydraCollectionResponse<Currency>>(basePath, {
    params: { pagination: false },
  })
}

function fetchById(id: number) {
  return fetchByIri(`${basePath}/${id}`)
}

function fetchByIri(iri: ApiResourceId) {
  return axios.get<Currency>(iri)
}

function deleteById(id: number) {
  return axios.delete<Currency>(`${basePath}/${id}`)
}

function save(currency: Partial<Currency>) {
  if (currency.id === undefined) {
    return axios.post<Currency>(basePath, currency)
  }
  return axios.put<Currency>(`${basePath}/${currency.id}`, currency)
}

export const currencyApi = {
  fetchByQuery,
  fetchAll,
  fetchById,
  fetchByIri,
  delete: deleteById,
  save,
}
