import axios from 'axios'
import type { HydraCollectionResponse } from '@js/types'
import type { RoleResource } from '@js/model/role'
import type { Unit } from '@js/model/unit'
import type { User } from '@js/model/user'
import type { UserGroup } from '@js/model/userGroup'

export const basePath = '/api/user-groups'

function fetchAll() {
  return axios.get<HydraCollectionResponse<UserGroup>>(basePath, {
    params: { pagination: false },
  })
}
function fetchById(id: UserGroup['id']) {
  return axios.get<UserGroup>(`${basePath}/${id}`)
}

function deleteById(id: UserGroup['id']) {
  return axios.delete(`${basePath}/${id}`)
}

function fetchAssignedRoles(id: UserGroup['id']) {
  return axios.get<HydraCollectionResponse<RoleResource>>(`${basePath}/${id}/roles`, {
    params: { pagination: false },
  })
}

function updateAssignedRoles(
  id: UserGroup['id'],
  updatedRoles: Array<NonNullable<RoleResource['@id']>>
) {
  return axios.put<HydraCollectionResponse<RoleResource>>(`${basePath}/${id}/roles`, {
    roles: updatedRoles,
  })
}

function fetchInheritedRoles(id: UserGroup['id']) {
  return axios.get<HydraCollectionResponse<RoleResource>>(`${basePath}/${id}/inherited-roles`, {
    params: { pagination: false },
  })
}

function fetchAssignedUnits(id: UserGroup['id']) {
  return axios.get<HydraCollectionResponse<Unit>>(`${basePath}/${id}/units`, {
    params: { pagination: false },
  })
}

function updateAssignedUnits(id: UserGroup['id'], updatedUnits: Array<NonNullable<Unit['@id']>>) {
  return axios.put<HydraCollectionResponse<Unit>>(`${basePath}/${id}/units`, {
    units: updatedUnits,
  })
}

function fetchAssignedUsers(id: UserGroup['id']) {
  return axios.get<HydraCollectionResponse<User>>(`${basePath}/${id}/direct-users`, {
    params: { pagination: false },
  })
}

function updateAssignedUsers(id: UserGroup['id'], updatedUsers: Array<NonNullable<User['@id']>>) {
  return axios.put<HydraCollectionResponse<User>>(`${basePath}/${id}/direct-users`, {
    directUsers: updatedUsers,
  })
}

export const userGroupApi = {
  fetchAll,
  fetchById,
  delete: deleteById,
  fetchAssignedRoles,
  fetchAssignedUnits,
  fetchAssignedUsers,
  fetchInheritedRoles,
  updateAssignedRoles,
  updateAssignedUnits,
  updateAssignedUsers,
}
